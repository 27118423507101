import React from 'react';
import ClusteringAnalysis from './components/analysis/ClusteringAnalysis';
import SentimentAnalysis from './components/analysis/SentimentAnalysis';
import SemanticSearch from './components/analysis/SemanticSearch';
import SemanticSearchResults from './components/analysis/SemanticSearchResults';

const AnalysisWrapper = ({ 
  analysisType,
  analysisResults,
  isDarkMode,
  onPointClick,
  onSearch
}) => {
  if (!analysisResults) return null;

  switch (analysisType) {
    case 'clustering':
      return (
        <ClusteringAnalysis
          clusters={analysisResults.clusters}
          clusterSummaries={analysisResults.cluster_summaries}
          isDarkMode={isDarkMode}
          onPointClick={onPointClick}
        />
      );
    case 'sentiment':
      return (
        <SentimentAnalysis
          results={analysisResults}  // Passa direttamente i risultati dell'analisi
          isDarkMode={isDarkMode}
        />
      );
    case 'semantic_search':
      return (
        <SemanticSearchResults
          results={analysisResults.results}
          query={analysisResults.query}
          isDarkMode={isDarkMode}
        />
      );
    default:
      return (
        <div className={`mt-6 p-4 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
          <p>Tipo di analisi non supportato</p>
        </div>
      );
  }
};

export default AnalysisWrapper;