import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Search, Loader } from 'lucide-react';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';

const SemanticSearch = ({ 
  results, 
  isDarkMode, 
  onSearch, 
  onDatasetUpload,
  multipleFiles = [],
  selectedColumns = [],
  appendMessage
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isDatasetProcessed, setIsDatasetProcessed] = useState(false);

  useEffect(() => {
    if (results) {
      console.log('Risultati ricerca semantica:', results);
    }
  }, [results]);

  // Fase 1: Upload e Processing Dataset
  const handleDatasetProcess = async () => {
    if (!multipleFiles.length) {
      setError('Seleziona un file da processare');
      return;
    }

    try {
      setIsLoading(true);
      setError(null);
      
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token di autenticazione mancante');
      }

      const formData = new FormData();
      formData.append('file', multipleFiles[0]);
      
      if (selectedColumns.length > 0) {
        selectedColumns.forEach(col => 
          formData.append('selected_columns', col)
        );
      }

      const response = await axios({
        method: 'post',
        url: `${API_BASE_URL}/embeddings/process-semantic-dataset`,
        data: formData,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data?.status === 'success') {
        setIsDatasetProcessed(true);
        appendMessage('Dataset processato con successo. Ora puoi effettuare ricerche.', 'success');
      } else {
        throw new Error('Formato risposta non valido');
      }

    } catch (error) {
      console.error('Errore:', error);
      const errorMessage = error.response?.data?.detail || error.message;
      setError(errorMessage);
      appendMessage(errorMessage, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  // Fase 2: Ricerca
  const handleSearch = async () => {
    if (!searchQuery.trim()) {
      setError('Inserisci un testo da cercare');
      return;
    }
    
    setIsLoading(true);
    setError(null);
    
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token di autenticazione mancante');
      }

      const response = await axios({
        method: 'post',
        url: `${API_BASE_URL}/embeddings/search-in-dataset`,
        data: { query: searchQuery.trim() },
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      console.log('Response dalla ricerca semantica:', response.data);

      if (response.data && response.data.type === 'semantic_search' && Array.isArray(response.data.results)) {
        onSearch(response.data);
        appendMessage('Ricerca completata con successo', 'success');
      } else {
        console.error('Risposta non valida:', response.data);
        throw new Error('Formato risposta non valido. Struttura attesa non trovata.');
      }

    } catch (error) {
      console.error('Errore durante la ricerca:', error);
      const errorMessage = error.response?.data?.detail || error.message;
      setError(errorMessage);
      appendMessage(errorMessage, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const formatMetadataValue = (value) => {
    if (typeof value === 'boolean') {
      return value ? 'Sì' : 'No';
    }
    if (typeof value === 'number') {
      return value.toLocaleString('it-IT');
    }
    return value;
  };

  return (
    <div className={`mt-6 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
      {/* Header */}
      <div className="mb-6">
        <h3 className="text-xl font-semibold">Ricerca Semantica</h3>
        <p className={`mt-2 text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
          {isDatasetProcessed 
            ? 'Cerca documenti simili al testo inserito'
            : 'Carica un dataset per iniziare la ricerca semantica'
          }
        </p>
      </div>
      
      {/* Fase 1: Dataset Processing */}
      {!isDatasetProcessed && (
        <div className={`p-6 rounded-xl shadow-sm ${
          isDarkMode ? 'bg-gray-800' : 'bg-white'
        }`}>
          <button
            onClick={handleDatasetProcess}
            disabled={isLoading || !multipleFiles.length}
            className={`w-full px-6 py-3 rounded-lg font-medium transition-colors ${
              isLoading || !multipleFiles.length
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700 text-white'
            } flex items-center justify-center`}
          >
            {isLoading ? (
              <Loader className="animate-spin" size={20} />
            ) : (
              'Processa Dataset per Ricerca Semantica'
            )}
          </button>
        </div>
      )}

      {/* Fase 2: Search Interface - Mostra solo dopo il processing del dataset */}
      {isDatasetProcessed && (
        <div className={`p-6 rounded-xl shadow-sm ${
          isDarkMode ? 'bg-gray-800' : 'bg-white'
        }`}>
          <div className="flex items-center gap-2">
            <div className="flex-1">
              <div className={`relative rounded-lg border ${
                isDarkMode 
                  ? 'bg-gray-700 border-gray-600' 
                  : 'bg-white border-gray-200'
              }`}>
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyDown={handleKeyPress}
                  placeholder="Inserisci il testo da cercare..."
                  className={`w-full p-3 pr-10 rounded-lg outline-none ${
                    isDarkMode 
                      ? 'bg-gray-700 text-white placeholder-gray-400' 
                      : 'bg-white text-gray-900 placeholder-gray-500'
                  }`}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <Search size={20} className="text-gray-400" />
                </div>
              </div>
            </div>
            <button
              onClick={handleSearch}
              disabled={isLoading || !searchQuery.trim()}
              className={`px-6 py-3 rounded-lg font-medium transition-colors ${
                isLoading || !searchQuery.trim()
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-blue-600 hover:bg-blue-700 text-white'
              } flex items-center justify-center min-w-[100px]`}
            >
              {isLoading ? (
                <Loader className="animate-spin" size={20} />
              ) : (
                'Cerca'
              )}
            </button>
          </div>
        </div>
      )}

      {/* Results */}
      {results?.type === 'semantic_search' && Array.isArray(results?.results) && (
        <div className="mt-8 space-y-6">
          <div className={`text-sm font-medium ${
            isDarkMode ? 'text-gray-300' : 'text-gray-600'
          }`}>
            {results.results.length} risultati trovati per "{results.query || searchQuery}"
          </div>
          {results.results.map((result) => (
            <div 
              key={result.id}
              className={`p-4 rounded-lg transition-all ${
                isDarkMode 
                  ? 'bg-gray-700 hover:bg-gray-600' 
                  : 'bg-gray-50 hover:bg-gray-100'
              }`}
            >
              {/* Result Text */}
              <div className="mb-2">
                <p>{result.text}</p>
              </div>
              
              {/* Similarity Score */}
              <div className="text-sm text-gray-500">
                Similarità: {(result.similarity * 100).toFixed(1)}%
              </div>

              {/* Metadata */}
              {result.metadata && Object.keys(result.metadata).length > 0 && (
                <div className="mt-2 pt-2 border-t border-gray-200 dark:border-gray-600">
                  <div className="text-sm font-medium mb-1">Metadati:</div>
                  <div className="grid grid-cols-2 gap-2">
                    {Object.entries(result.metadata).map(([key, value]) => (
                      <div key={key} className="text-sm">
                        <span className="text-gray-500">{key}:</span>{' '}
                        <span>{formatMetadataValue(value)}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* No Results */}
      {results?.results && results.results.length === 0 && !isLoading && (
        <div className="mt-8 text-center py-8">
          <p className={`text-sm ${
            isDarkMode ? 'text-gray-400' : 'text-gray-500'
          }`}>
            Nessun risultato trovato per la tua ricerca
          </p>
        </div>
      )}

      {/* Error Message */}
      {error && (
        <div className="mt-4 p-3 rounded-lg bg-red-100 border border-red-300 text-red-700">
          {error}
        </div>
      )}
    </div>
  );
};

SemanticSearch.propTypes = {
  results: PropTypes.shape({
    type: PropTypes.string,
    query: PropTypes.string,
    results: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      similarity: PropTypes.number.isRequired,
      metadata: PropTypes.object
    }))
  }),
  isDarkMode: PropTypes.bool.isRequired,
  onSearch: PropTypes.func.isRequired,
  onDatasetUpload: PropTypes.func.isRequired,
  multipleFiles: PropTypes.array,
  selectedColumns: PropTypes.array,
  appendMessage: PropTypes.func.isRequired
};

export default SemanticSearch;