import React from 'react';

const SemanticSearchResults = ({ results, query, isDarkMode }) => {
  if (!results || results.length === 0) {
    return (
      <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
        <p>Nessun risultato trovato per la query: "{query}"</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium">
        Risultati per: "{query}"
      </h3>
      <div className="space-y-4">
        {results.map((result) => (
          <div
            key={result.id}
            className={`p-4 rounded-lg ${
              isDarkMode ? 'bg-gray-700' : 'bg-gray-50'
            }`}
          >
            <div className="mb-2">
              <p>{result.text}</p>
            </div>
            <div className="text-sm text-gray-500">
              Similarità: {(result.similarity * 100).toFixed(1)}%
            </div>
            {Object.keys(result.metadata).length > 0 && (
              <div className="mt-2 pt-2 border-t border-gray-200">
                <div className="text-sm">
                  {Object.entries(result.metadata).map(([key, value]) => (
                    <div key={key}>
                      <span className="font-medium">{key}:</span>{' '}
                      <span>{value}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SemanticSearchResults; 